import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { useMsal } from "@azure/msal-react";
import { NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AppLogo2 from "../Icons/appLogo.png";
import { TextField, Checkbox, Button, FormLabel } from "@mui/material";
import "../Common.css";

const ContributorPage = () => {
  const { instance } = useMsal();
  const [primarySkills, setPrimarySkills] = useState("");
  const [secondarySkills, setSecondarySkills] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [buttonColor, setButtonColor] = useState("lightblue");
  const [showNotification, setShowNotification] = useState(false);
  const [employeeData, setEmployeeData] = useState(null);

  useEffect(() => {
    const userEmail = instance.getAllAccounts()[0]?.username;

    if (userEmail) {
      fetch(`https://prod.appstudioltts.com/api/empdata/employees/${userEmail}`)
        .then((response) => response.json())
        .then((data) => {
          localStorage.setItem("employeeData", JSON.stringify(data));
          setEmployeeData(data);
        })
        .catch((error) => {
          console.error("Error fetching employee data:", error);
        });
    }
  }, []);

  useEffect(() => {
    if (showNotification) {
      const timer = setTimeout(() => {
        setShowNotification(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showNotification]);

  const handlePrimarySkillsChange = (event) => {
    setPrimarySkills(event.target.value);
    updateButtonColor(event.target.value, secondarySkills, termsChecked);
  };

  const handleSecondarySkillsChange = (event) => {
    setSecondarySkills(event.target.value);
    updateButtonColor(primarySkills, event.target.value, termsChecked);
  };

  const handleTermsChange = () => {
    setTermsChecked(!termsChecked);
    updateButtonColor(primarySkills, secondarySkills, !termsChecked);
  };

  const updateButtonColor = (primary, secondary, terms) => {
    if (primary.trim() && secondary.trim() && terms) {
      setButtonColor("#005DFF");
    } else {
      setButtonColor("lightblue");
    }
  };

  const handleCancel = () => {
    console.log("Cancel button clicked");
  };

  const handleApprove = async () => {
    const userEmail = instance.getAllAccounts()[0].username;
    const userName = instance.getAllAccounts()[0].name;
    var empData = localStorage.getItem("employeeData");
    var employeeData = JSON.parse(empData);

    if (Array.isArray(employeeData) && employeeData.length > 0) {
      const dataToSend = {
        primary_skills: primarySkills,
        secondary_skills: secondarySkills,
        SenderName: userName,
        Email: userEmail,
        DeliveryUnit: employeeData[0].DeliveryUnit,
        DeliveryGroup: employeeData[0].DeliveryGroup,
      };

      try {
        // Send data to the skills update API
        const skillsResponse = await fetch(
          "https://prod.appstudioltts.com/api/skills/skillsupdate",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataToSend),
          }
        );

        if (skillsResponse.ok) {
          // Fetch user data from the auth API
          const authResponse = await fetch(
            `https://prod.appstudioltts.com/api/auth`
          );
          const authData = await authResponse.json();

          // Find the user with the matching email
          const user = authData.find((user) => user.Email === userEmail);

          if (user) {
            // Update the UserType to contributor
            const updateResponse = await fetch(
              `https://prod.appstudioltts.com/api/auth/update/${user.id}`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ UserType: "contributor" }),
              }
            );

            if (updateResponse.ok) {
              console.log("UserType updated successfully!");
            } else {
              console.error("Failed to update UserType.");
            }
          } else {
            console.error("User not found in auth data.");
          }

          // Send email notification
          const emailResponse = await fetch(
            `https://prod.appstudioltts.com/api/skillupdate-mail/send-email`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: userEmail,
              }),
            }
          );

          if (emailResponse.ok) {
            console.log("Email sent successfully!");
          } else {
            console.error("Failed to send email.");
          }

          setShowNotification(true);
          console.log("Data sent successfully!");
          setPrimarySkills("");
          setSecondarySkills("");
          setTermsChecked(false);
        } else {
          console.error("Failed to send data.");
        }
      } catch (error) {
        console.error("Error sending data:", error);
      }
    }
  };

  const handleCloseSuccessPopup = () => {
    setShowNotification(false);
  };

  const handleClose = () => {
    console.log("Close button clicked");
  };

  return (
    <Grid container direction="column" className="contribute-bg">
      <Grid item>
        <AppBar position="static" className="HeaderCss">
          <Toolbar>
            <img className="AppLogo" src={AppLogo2} alt="App Logo" />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              App Studio Community
            </Typography>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid item container justifyContent="center">
        <Grid item xs={12} sm={10} md={10} lg={10}>
          <div className="containerAboveDiv">
            <div className="containerDiv">
              <div className="CloseIcon">
                <h3 className="h3StyleContPage">Enter Your Skills</h3>
                <NavLink to="/home">
                  <FaTimes
                    className="NavlinkCancelIcon"
                    onClick={handleCancel}
                    size={20}
                  />
                </NavLink>
              </div>
              <hr className="hrStyleContPage" />
              <Grid container spacing={2}>
                <Grid item xs={5.5}>
                  <b> Select primary skills </b>
                  <TextField
                    fullWidth
                    value={primarySkills}
                    placeholder="enter your primary skills"
                    onChange={handlePrimarySkillsChange}
                    required
                    InputProps={{
                      classes: {
                        root: 'customTextField',
                        input: 'customTextField',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={5.5}>
                  <b>Select secondary skills </b>
                  <TextField
                    fullWidth
                    value={secondarySkills}
                    placeholder="enter your secondary skills"
                    onChange={handleSecondarySkillsChange}
                    required
                    InputProps={{
                      classes: {
                        root: 'customTextField',
                        input: 'customTextField',
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <hr className="hr2StyleContPage" />
              <div>
                <h3 className="h3StylecontrPage">Terms and Conditions</h3>
                <ul>
                  <li>
                    A separate branch with your PS no_component name has to be
                    created in the New Components repository on Azure{" "}
                  </li>
                  <li>
                    Standalone application should be working as per the
                    requirement
                  </li>
                  <li>
                    For each component, the input has to come from a JSON file.{" "}
                  </li>
                  <li>Any changes to JSON has to be reflected in the output. </li>
                  <li>
                    The component code shall not affect/change the existing
                    components/playground/libraries in any way.{" "}
                  </li>
                </ul>
                <Checkbox
                  checked={termsChecked}
                  onChange={handleTermsChange}
                  color="primary"
                />
                <strong>I agree to the terms and conditions</strong>
              </div>
              <div className="buttonsDiv">
                <Grid container spacing={2} className="center-btn-1">
                  <Grid item xs={3}>
                    <NavLink to="/home">
                      <Button  variant="outlined" fullWidth className="buttonCancel txt-trans-s" onClick={handleCancel} >
                        Cancel
                      </Button>
                    </NavLink>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{
                        backgroundColor: buttonColor,
                        color: "white",
                        cursor: "pointer",
                        textTransform: "unset",
                      }}
                      disabled={
                        !termsChecked ||
                        !primarySkills.trim() ||
                        !secondarySkills.trim()
                      }
                      onClick={handleApprove}>
                      Send for Approval
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <div className="notificationContainer">
                {showNotification && (
                  <div className="notificationPopup">
                    <p>Data sent successfully!</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContributorPage;

